var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('layout',{attrs:{"width":"420"}},[_c('template',{slot:"header"},[_vm._v(" Add existing object ")]),[_c('v-form',[_c('validation-provider',{attrs:{"name":"Schema type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.schemaTypes,"error-messages":errors,"item-text":"name","item-value":"id","label":"Schema type"},model:{value:(_vm.state.schemaType),callback:function ($$v) {_vm.$set(_vm.state, "schemaType", $$v)},expression:"state.schemaType"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Schema name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.schemas,"loading":_vm.schemasLoading,"error-messages":errors,"item-text":"name","item-value":"id","label":"Schema name"},model:{value:(_vm.state.schemaId),callback:function ($$v) {_vm.$set(_vm.state, "schemaId", $$v)},expression:"state.schemaId"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Object name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.existingObjects,"loading":_vm.existingObjectsLoading,"error-messages":errors,"item-text":"name","item-value":"id","label":"Object"},model:{value:(_vm.state.existingObjectId),callback:function ($$v) {_vm.$set(_vm.state, "existingObjectId", $$v)},expression:"state.existingObjectId"}})]}}],null,true)})],1)],_c('template',{slot:"footer"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"text-primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary","depressed":"","disabled":invalid,"loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.exec.apply(null, arguments)}}},[_vm._v(" Add ")])],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }